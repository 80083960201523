<template>
  <div>
    <div class="container-fluid link-wrap">
      <div class="footer-wrap">
        <img style="height:16px;margin-right:8px;margin-top:-4px;" :src="require('@/assets/footer/linkTwo.png')" /><span>友情链接:</span>
        <a href="https://www.shenzhenql.com" target="_blank">深圳市企业联合会</a>
        <a href="https://www.wanhengtech.com/" target="_blank">万恒科技</a>
      </div>
    </div>
    <div class="bott">
      <div class="footer-wrap d-flex">
        <div class="left">
          <div class="d-flex f-connect">
            <div style="margin-right: 24px;">
              <img :src="require('@/assets/footer/wx_public.jpg')" />
              <div class="f-tit3">关注晟晟科技</div>
            </div>
            <div>
              <img :src="require('@/assets/footer/wx_connect.png')" />
              <div class="f-tit3">联系我们</div>
            </div>
          </div>
          <div>
            <div class="f-tit" style="margin-top: 18px;">服务热线</div>
            <div class="f-tit2" style="margin-bottom: 24px;">400-6898-881</div>
          </div>
          <div>
            <div class="f-tit">前台座机</div>
            <div class="f-tit2">0755-83479909</div>
          </div>
        </div>
        <div class="right">
          <div class="d-flex f-fun">
            <div>
              <div class="f-tit">产品中心</div>
              <div class="f-tit2" @click="toPage('purchases')">企业一站式采销平台</div>
              <div class="f-tit2" @click="toPage('supply')">聚合供应链</div>
            </div>
            <div>
              <div class="f-tit">行业解决方案</div>
              <div class="f-tit2" @click="toPage('collection')">政企集采</div>
              <div class="f-tit2" @click="toPage('benefits')">政企福利</div>
              <div class="f-tit2" @click="toPage('group')">团购/分销</div>
              <div class="f-tit2" @click="toPage('integral')">积分商城</div>
            </div>
            <div>
              <div class="f-tit" @click="toPage('service')">服务支撑</div>
              <div class="f-tit2">技术与交付</div>
              <div class="f-tit2">合作代理</div>
              <div class="f-tit2">售后服务</div>
            </div>
            <div>
              <div class="f-tit" @click="toPage('about')">关于我们</div>
              <div class="f-tit2" @click="toPage('about')">公司简介</div>
              <div class="f-tit2" @click="toPage('about')">联系我们</div>
            </div>
          </div>
          <div class="position-relative">
            <p class="f-tit com-tit">公司地址</p>
            <p class="f-tit2">
              深圳市南山区粤海街道深圳湾科技生态园10栋A座27楼
            </p>
            <img class="f-icon" :src="require('@/assets/footer/logo.png')" />
          </div>
        </div>
      </div>
      <div class="f-line"></div>

      <div class="f-copy f-tit2">深圳市晟晟科技有限公司 | <a class="f-tit2" style="cursor:pointer;" href="http://beian.miit.gov.cn/" target="_blank">粤ICP备2021011638号</a> | 版权所有</div>
      <div class="link-ewm">
        <img src="../assets/footer/link.png" />
      </div>
      <div class="toTop" @click="toTop">
        <img src="../assets/footer/toTop.png" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
    data() {
        return {}
    },
    methods: {
        tomessage: function (e) {
            window.location.href = e
        },
        toTop() {
            document.getElementById("nav").scrollIntoView({ behavior: "smooth" });
        },
        toPage(name) {
            this.$router.push({
                path: '/' + name
            })
        }
    }
}
</script>
<style scoped lang="scss">
.link-wrap {
    background: #f5f5f57a;
    color: rgba(0, 0, 0, 0.65);
    font-size: 16px;
    height: 50px;
    line-height: 50px;
    a {
        color: rgba(0, 0, 0, 0.6);
        margin-left: 20px;
        &:hover {
            color: blue;
        }
    }
}
.bott {
    width: 100%;
    background: #000c1a;
    padding: 40px 0px;
    min-width: 1200px;
}
.f-tit {
    font-size: 16px;
    color: rgba(255, 255, 255, 0.85);
    margin-bottom: 24px;
    &.com-tit {
        margin-bottom: 8px;
        margin-top: 100px;
    }
}
.f-tit2 {
    font-size: 14px;
    color: rgba(255, 255, 255, 0.45);
    margin-bottom: 12px;
}
.f-tit3 {
    font-size: 14px;
    color: rgba(255, 255, 255, 0.65);
    text-align: center;
}
.footer-wrap {
    width: 1200px;
    margin: 0px auto;
}
.left {
    width: 330px;
    .f-connect {
        > div {
            line-height: 60px;
        }
        img {
            width: 100px;
            height: 100px;
            vertical-align: top;
        }
    }
}
.right {
    width: 810px;
    margin-left: 60px;
    .f-fun {
        > div {
            flex: 1;
        }
    }
}
.f-line {
    width: 1200px;
    margin: 30px auto 40px;
    height: 0px;
    opacity: 1;
    border: 1px solid rgba(255, 255, 255, 0.25);
}
.f-copy {
    text-align: center;
    margin-bottom: 0px;
}
.f-icon {
    position: absolute;
    right: 130px;
    top: 16px;
}
.link-ewm {
    position: fixed;
    right: 60px;
    bottom: 380px;
    z-index: 200;
}
.toTop {
    position: fixed;
    right: 60px;
    bottom: 100px;
    z-index: 200;
    cursor: pointer;
}
@media only screen and (max-width: 900px) {
    .link-ewm,
    .toTop {
        display: none;
    }
}
@media only screen and (min-width: 901px) {
    .link-ewm,
    .toTop {
        display: block;
    }
}
</style>