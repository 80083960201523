import Vue from "vue";
import VueRouter from "vue-router";
import Index from "../views/Index.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/index",
    name: "Index",
    component: () => import("../views/Index.vue"),
    meta:{
      title:"晟晟科技-数字化集采及聚合供应链一站式服务平台"
    }
  },
  {
    path: "/about",
    name: "About",
    component: () => import("../views/About.vue"),
    meta:{
      title:"关于我们-晟晟科技"
    }
  },
  {
    path: "/supply",
    name: "Supply",
    component: () => import("../views/Supply.vue"),
    meta:{
      title:"聚合供应链-晟晟科技"
    }
  },
  {
    path: "/benefits",
    name: "Benefits",
    component: () => import("../views/Benefits.vue"),
    meta:{
      title:"政企福利-晟晟科技"
    }
  },
  {
    path: "/collection",
    name: "Collection",
    component: () => import("../views/Collection.vue"),
    meta:{
      title:"政企集采-晟晟科技"
    }
  },
  {
    path: "/group",
    name: "Group",
    component: () => import("../views/Group.vue"),
    meta:{
      title:"团购/分销-晟晟科技"
    }
  },
  {
    path: "/integral",
    name: "Integral",
    component: () => import("../views/Integral.vue"),
    meta:{
      title:"积分商城-晟晟科技"
    }
  },
  {
    path: "/purchases",
    name: "Purchases",
    component: () => import("../views/Purchases.vue"),
    meta:{
      title:"企业一站式采销平台-晟晟科技"
    }
  },
  {
    path: "/service",
    name: "Service",
    component: () => import("../views/Service.vue"),
    meta:{
      title:"服务支撑-晟晟科技"
    }
  },
  {
    path: "/*",
    name: "Index",
    component: Index,
    meta:{
      title:"晟晟科技-数字化集采及聚合供应链一站式服务平台"
    }
  }
];
//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push;
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err);
};
const router = new VueRouter({
  routes,
  mode:'history'
});

router.beforeEach((to,from,next)=>{
  if(to.meta.title){//判断是否有标题
      document.title = to.meta.title
  }
  next()  
})


export default router;
